.dashboardContainer {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  background-color: #232b36;
  color: #ffffff;
  .userDetails {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 30px;
    height: max-content;
    background-size: cover;
    .userIcon {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border-radius: 50px;
      background-color: #ffffff;
      background-size: cover;
      overflow: hidden;
      color: #000000;
    }
    .userDetailsModal {
      position: absolute;
      top: 100px;
      padding: 20px;
      background-color: #191f27;
      border-radius: 3px;
      .userLogo {
        max-width: 100px;
        max-height: 100px;
      }
      .companyName {
        text-align: center;
      }
      .logoutButtonContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        color: white;
        text-align: center;
        flex-shrink: 0;

        .logoutButton {
          border-radius: 5px;
          color: white;
          background-color: #f54c4c;
          padding: 10px 30px;
          font-size: 1rem;
        }
      }
    }
  }
  .quicksellInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .quicksellLogo {
      width: 200px;
      height: 200px;
    }
    .appTitle {
      margin-top: 50px;
      font-size: 24px;
      font-weight: 700;
    }
  }
  .dashboardActionsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;

    .titleSection {
      display: flex;
      flex-direction: row;
      flex: 1 1 0;
      margin-bottom: 25px;
      .title {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        font-size: 18px;
      }
    }

    .contentSection {
      display: flex;
      flex-direction: row;
      flex: 1 1 0;
      margin-bottom: 10px;

      .syncSection {
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        justify-content: center;
        align-items: center;

        .syncingOrders {
          display: flex;
          flex-direction: row;

          .syncingLoader {
            margin: 0px;
          }

          .syncingTitle {
            margin-left: 20px;
            font-size: 16px;
            color: #ffffff;
          }
        }

        .syncNow {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .syncNowTitle {
            color: #9caabd;
            margin-bottom: 20px;
          }
          .syncNowButton {
            padding: 12px 22px;
            cursor: pointer;
            color: #fff;
            -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.42);
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.42);
            border-radius: 3px;
            text-shadow: 0 -1px #1e5f3f;
            text-transform: uppercase;
            font-size: 16px;
            background-image: -webkit-gradient(
              linear,
              left bottom,
              left top,
              from(#2a714e),
              to(#50b182)
            );
            background-image: -webkit-linear-gradient(bottom, #2a714e, #50b182);
            background-image: -o-linear-gradient(bottom, #2a714e 0, #50b182 100%);
            background-image: linear-gradient(0deg, #2a714e, #50b182);

            &:hover {
              background-image: linear-gradient(0deg, #3c8964, #a6ffd4);
            }
          }
        }

        .ordersSynced {
          margin: auto;
          color: #9caabd;
        }
      }

      .excelSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 1 0;

        .excelDownloadButton {
          cursor: pointer;
          color: #fff;
          -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.42);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.42);
          border-radius: 3px;
          text-shadow: 0 -1px #1e5f3f;
          text-transform: uppercase;
          font-size: 16px;
          background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            from(#2a714e),
            to(#50b182)
          );
          background-image: -webkit-linear-gradient(bottom, #2a714e, #50b182);
          background-image: -o-linear-gradient(bottom, #2a714e 0, #50b182 100%);
          background-image: linear-gradient(0deg, #2a714e, #50b182);
          padding: 12px 22px;
        }
        .downloadExcelMessage {
          color: #9caabd;
          margin-top: 20px;
        }
      }
    }
  }
}
